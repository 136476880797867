<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

    <el-row :gutter="40">
      <el-col :span="8">
        <el-form-item label="План" prop="fact">
          <el-input
            v-model="scale.plan"
            v-number-format
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col :span="8">
        <el-form-item label="Факт выполнения" prop="fact">
          <el-input
            v-model="scale.fact"
            v-number-format
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>



  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "quantity-library-edit-form",
  mixins: [],
  components: {},

  props: {
    scale: {type: Object, required: true},
  },

  computed: {},

  watch: {},

  mounted() {
    // this.$nextTick(function () {
    //   this.$refs.editForm.clearValidate();
    // });

  },

  data() {
    return {

      rules: {}

    }
  },

  methods: {

  }
}
</script>

<style>

</style>